import React, { useState } from 'react';
import { Formik, Form, Field } from 'formik';
import classNames from 'classnames';
import Button from '../Button';

import './Form.scss';

const handleSubmit = ({ name, email, text, order: type }) => {
  const body = {
    name,
    email,
    text,
    type,
  };

  return fetch("https://us-central1-solidone-cloud.cloudfunctions.net/submitForm2", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(body),
  }).then(function(response) {
    if (response.status >= 400 && response.status < 600) {
      throw new Error("Bad response from server");
    }
    return response;
  });
};

const FormSend = ({
    btnTitle = 'Get in touch!',
    modalField = false,
    initialOrderValue = 'order-solution',
    initialText = '',
}) => {
  const [submitted, setSubmitted] = useState(false);
  const [rejected, setRejected] = useState(false);

  return (
    <div className={classNames('Form', { 'is-success': submitted })}>
      <Formik
        enableReinitialize
        initialValues={{ email: '', name: '',  text: initialText, order: initialOrderValue}}
        validate={values => {
          const errors = {};

          if (!values.email) {
            errors.email = 'Required';
          } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
            errors.email = 'Invalid email address';
          }

          if (!values.name) {
            errors.name = 'Required';
          } else if (values.email === '') {
            errors.name = 'Field is empty';
          }

          return errors;
        }}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            await handleSubmit(values);
            setSubmitted(true);
            setSubmitting(false);
          } catch(e) {
            setRejected(true);
            setSubmitted(false);
            setSubmitting(false);
          }
        }}
      >
        {({ isSubmitting, errors }) => (
          <Form>
            <div className="Form__submit">Successfully sent!</div>
            <div className="Form__inner">
              <Field
                type="email"
                name="email"
                placeholder="Your Email"
                className={classNames('Form__input', { 'is-error': errors.email || rejected })}
              />
              <Field
                type="text"
                name="name"
                placeholder="Your Name"
                className={classNames('Form__input', { 'is-error': errors.name || rejected })}
              />
              {modalField &&
                <div className="Form__select">
                  <Field
                    as="select"
                    name="order"
                    className="Form__input"
                  >
                    <option value="order-solution">Order a solution</option>
                    <option value="order-fixed">Order a fixed price project</option>
                    <option value="order-agile">Order an agile project</option>
                    <option value="order-dedicated">Order a dedicated team project</option>
                    <option value="learn-more">Learn more</option>
                  </Field>
                </div>
              }
              <Field
                component="textarea"
                name="text"
                placeholder="Let’s talk"
                className="Form__textarea"
              />
              <div className="Form__btn-wrapper">
                <Button
                  buttonClassName="Form__btn"
                  type="submit"
                  disabled={isSubmitting}
                  title={btnTitle}
                  span
                />
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default FormSend;
