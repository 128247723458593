import React from 'react';
import classNames from 'classnames';

import './Button.scss';

const Button = ({
  title,
  type = 'button',
  sizeSM,
  span = false,
  buttonClassName,
  onClick,
  name,
}) => {
  return (
    <button
      name={name}
      className={classNames('Button', buttonClassName, { 'Button--sm': sizeSM })}
      type={type}
      onClick={onClick}
    >
      {title}
      {span && <span />}
    </button>
  );
};

export default Button;