import React from 'react';

import Form from '../FormSend';

import formSectionImage from '../../../static/img/form/deal.svg';

const Contact = ({data}) => {
    const { frontmatter = {} } = data.markdownRemark || {};

    return (
        <section id="contact" className="Section__form">
            <div className="container">
            <div className="Section__form-grid">
                <div className="Section__form-item">
                <h2>{frontmatter?.form?.title}</h2>
                <Form />
                </div>
                <div className="Section__form-item">
                <img
                    className="Section__form-img"
                    src={formSectionImage}
                    alt="SolidOne"
                />
                </div>
            </div>
            </div>
        </section>
    );
}

export default Contact;